import React, { Component } from "react";

class TermsOfService extends Component {
  render() {
    return (
      <div
        style={{
          marginTop: 150,
          marginLeft: 200,
          marginRight: 200,
          justifyContent: "center",
          marginBottom: 150,
        }}
      >
        <h1
          style={{
            textAlign: "center",
            marginTop: 40,
          }}
        >
          <strong>Privacy Policy</strong>
        </h1>

        <h1>Privacy Policy</h1>
        <p style={{ marginBottom: 15 }}>
          <strong>Effective Date:</strong> 12 August 2023
        </p>

        <h2>1. Introduction</h2>
        <p style={{ marginBottom: 15 }}>
          Asritsolutions LLC ("we," "our," or "us") respects your privacy and is
          committed to protecting the personal information you share with us.
          This Privacy Policy explains how we collect, use, and share
          information when you use our SMS services through the RingCentral
          platform, as well as your rights related to that information.
        </p>

        <h2>2. Information We Collect</h2>
        <p style={{ marginBottom: 15 }}>
          When you interact with our SMS services via RingCentral, we may
          collect the following types of information:
        </p>
        <ul>
          <li>
            <strong>Personal Information:</strong> This includes your name,
            phone number, and any other information you provide when you opt-in
            to receive SMS communications through our RingCentral-powered
            services.
          </li>
          <li>
            <strong>SMS Communication Data:</strong> This includes the content
            of the messages you send or receive, delivery status, timestamps,
            and any data related to your interaction with our SMS services on
            RingCentral.
          </li>
          <li>
            <strong>Technical Data:</strong> This includes your IP address,
            device information, and details about how you access and interact
            with our SMS services through RingCentral.
          </li>
          <li>
            <strong>Usage Data:</strong> Information about how you use our
            RingCentral-powered services, such as the frequency and timing of
            your messages.
          </li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p style={{ marginBottom: 15 }}>
          We use the information collected via RingCentral for the following
          purposes:
        </p>
        <ul>
          <li>
            <strong>Providing SMS Services:</strong> To send you SMS
            notifications, alerts, or promotional messages based on your opt-in
            preferences via RingCentral.
          </li>
          <li>
            <strong>Customer Support:</strong> To assist you with any inquiries
            or issues related to our SMS services through RingCentral.
          </li>
          <li>
            <strong>Marketing and Promotions:</strong> To send you marketing
            messages and special offers, where you have provided consent. You
            can opt-out at any time by following the instructions provided in
            our messages.
          </li>
          <li>
            <strong>Service Improvements:</strong> To analyze usage data from
            RingCentral and improve our SMS services and overall user
            experience.
          </li>
          <li>
            <strong>Legal Compliance:</strong> To comply with legal obligations,
            resolve disputes, and enforce our agreements, particularly in the
            context of RingCentral's terms of service.
          </li>
        </ul>

        <h2>4. Sharing Your Information</h2>
        <p style={{ marginBottom: 15 }}>
          We may share your information in connection with RingCentral in the
          following ways:
        </p>
        <ul>
          <li>
            <strong>RingCentral Service Providers:</strong> We share information
            with RingCentral, which acts as our third-party service provider to
            facilitate SMS communications. RingCentral may also have access to
            your information for purposes related to providing their services,
            such as message routing and delivery.
          </li>
          <li>
            <strong>Other Third-Party Service Providers:</strong> We may share
            information with other vendors who assist us in operating our
            services (e.g., analytics providers, cloud storage providers). These
            providers are contractually obligated to protect your information
            and only use it as directed by us.
          </li>
          <li>
            <strong>Legal Requirements:</strong> If required by law, we may
            disclose your information to governmental authorities or other
            parties in response to legal requests, such as subpoenas or court
            orders.
          </li>
          <li>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or sale of our business, your information may be
            transferred to the new owners.
          </li>
        </ul>

        <h2>5. RingCentral-Specific Considerations</h2>
        <p style={{ marginBottom: 15 }}>
          Our SMS services are provided through RingCentral, a third-party
          communications platform. By using our services, you acknowledge and
          agree to RingCentral's{" "}
          <a href="https://www.ringcentral.com/legal/privacy-notice.html">
            Privacy Notice
          </a>{" "}
          and{" "}
          <a href="https://www.ringcentral.com/legal/tos.html">
            Terms of Service
          </a>
          . We recommend reviewing these documents to understand how RingCentral
          may process your information independently of our services.
        </p>

        <h2>6. SMS Opt-In and Opt-Out</h2>
        <p style={{ marginBottom: 15 }}>
          We only send SMS messages via RingCentral to users who have opted in
          to receive them. You can opt-in by providing your consent through our
          website, a text message, or another method specified by us. If you
          wish to stop receiving SMS messages from us, you can opt-out at any
          time by following the opt-out instructions provided in the messages or
          by contacting us directly.
        </p>

        <h2>7. Data Retention</h2>
        <p style={{ marginBottom: 15 }}>
          We retain your personal information and SMS communication data only
          for as long as necessary to fulfill the purposes outlined in this
          Privacy Policy, unless a longer retention period is required or
          permitted by law. RingCentral may retain certain data as required by
          their own policies and applicable laws.
        </p>

        <h2>8. Security</h2>
        <p style={{ marginBottom: 15 }}>
          We take reasonable steps to protect your personal information from
          unauthorized access, use, or disclosure. However, no method of
          transmission over the internet or via SMS is 100% secure, and we
          cannot guarantee its absolute security. RingCentral implements its own
          security measures, which are detailed in their{" "}
          <a href="https://www.ringcentral.com/legal/security.html">
            Security Policy
          </a>
          .
        </p>

        <h2>9. Children's Privacy</h2>
        <p style={{ marginBottom: 15 }}>
          Our SMS services are not intended for use by children under the age of
          13. We do not knowingly collect personal information from children
          under 13. If we become aware that we have inadvertently collected
          personal information from a child under 13, we will take steps to
          delete such information promptly.
        </p>

        <h2>10. Changes to This Privacy Policy</h2>
        <p style={{ marginBottom: 15 }}>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and the updated policy will take effect upon
          posting. We encourage you to review this policy periodically to stay
          informed about how we are protecting your information.
        </p>

        <h2>11. Contact Us</h2>
        <p style={{ marginBottom: 15 }}>
          If you have any questions or concerns about this Privacy Policy or our
          SMS data practices, please contact us at:
        </p>
        <p style={{ marginBottom: 15 }}>
          Asritsolutions LLC
          <br />
          4900 Hopyard Rd Ste 100
          <br />
          Pleasanton, CA 94588 (US)
          <br />
          support@asritsolutions.com
          <br />
          +1 (408) 520 - 0014
        </p>
      </div>
    );
  }
}
export default TermsOfService;
